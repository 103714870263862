<template>
  <div></div>
</template>

<script>
export default {
  title: '비밀번호 재설정 - 유효시간 초과',
};
</script>

<style lang="scss" scoped></style>

<style scoped></style>

<style></style>
